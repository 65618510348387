import { CONNECTOR_TYPES } from '@/vars/general'
import { maskPhonePlain } from '@root/src/utils'
import moment from 'moment'
import { batchModeSelectedCommunication, ManualCommunication } from '../../../types/GCB2'

/**
 *
 * @param {Object} item
 * @returns  возвращает ссылку на клиента в одной из систем
 */
export function getClientRef(hrefInfoItem: any) {
    if (hrefInfoItem.connectorType === CONNECTOR_TYPES.yClients) {
        return `https://yclients.com/clients/${hrefInfoItem.company_id}/base?fields%5B0%5D=name&fields%5B1%5D=phone&fields%5B2%5D=email&fields%5B3%5D=sold_amount&fields%5B4%5D=visits_count&fields%5B5%5D=discount&fields%5B6%5D=last_visit_date&fields%5B7%5D=first_visit_date&order_by=id&order_by_direction=desc&page=1&page_size=25&segment=&operation=AND&filters%5B0%5D%5Boperation%5D=OR&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B1%5D%5Btype%5D=quick_search&filters%5B1%5D%5Bstate%5D%5Bvalue%5D=${hrefInfoItem.phone}`
    }
    if (hrefInfoItem.connectorType === 'waLink') {
        return `https://wa.me/${maskPhonePlain(hrefInfoItem.phone)}?text=${encodeURIComponent(
            hrefInfoItem.text
        )}`
    }
    if (hrefInfoItem.connectorType === CONNECTOR_TYPES.profSalon) {
        return `https://profsalon.org/CRM/${hrefInfoItem.profSalonLogin}/clients/card/history/#!/uid=${hrefInfoItem.clientId}`
    }
}
export function getYcClientRef(item: any) {
    const hrefInfo = JSON.parse(item.hrefInfo)
    const HrefInfoYc = hrefInfo.find((el: any) => el.connectorType === CONNECTOR_TYPES.yClients)
    return `https://yclients.com/clients/${HrefInfoYc.company_id}/base?fields%5B0%5D=name&fields%5B1%5D=phone&fields%5B2%5D=email&fields%5B3%5D=sold_amount&fields%5B4%5D=visits_count&fields%5B5%5D=discount&fields%5B6%5D=last_visit_date&fields%5B7%5D=first_visit_date&order_by=id&order_by_direction=desc&page=1&page_size=25&segment=&operation=AND&filters%5B0%5D%5Boperation%5D=OR&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B1%5D%5Btype%5D=quick_search&filters%5B1%5D%5Bstate%5D%5Bvalue%5D=${HrefInfoYc.phone}`
}
export function getClientRefFromParsed(hrefInfo: any) {
    if (hrefInfo.connectorType === CONNECTOR_TYPES.yClients) {
        return `https://yclients.com/clients/${hrefInfo.company_id}/base?fields%5B0%5D=name&fields%5B1%5D=phone&fields%5B2%5D=email&fields%5B3%5D=sold_amount&fields%5B4%5D=visits_count&fields%5B5%5D=discount&fields%5B6%5D=last_visit_date&fields%5B7%5D=first_visit_date&order_by=id&order_by_direction=desc&page=1&page_size=25&segment=&operation=AND&filters%5B0%5D%5Boperation%5D=OR&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B1%5D%5Btype%5D=quick_search&filters%5B1%5D%5Bstate%5D%5Bvalue%5D=${hrefInfo.phone}`
    }
    if (
        hrefInfo.connectorType === CONNECTOR_TYPES.profSalon ||
        hrefInfo.connectorType === CONNECTOR_TYPES.profSalonBat
    ) {
        const link = `https://profsalon.org/CRM/${hrefInfo.profSalonLogin}/clients/card/history/#!/uid=${hrefInfo.clientId}`
        console.log({ link })
        return link
    }
    if (hrefInfo.connectorType === 'waLink') {
        //Если указан шаблон ссылки, используем его
        if (hrefInfo.link_template) {
            return hrefInfo.link_template
                .replace('{{phone}}', maskPhonePlain(hrefInfo.phone))
                .replace('{{text}}', encodeURIComponent(hrefInfo.text))
        } else {
            return `https://wa.me/${maskPhonePlain(hrefInfo.phone)}?text=${encodeURIComponent(hrefInfo.text)}`
        }
    }
}
export function dateFilter(el: any, startDate: string, endDate: string) {
    if (
        moment(el.date).isSameOrAfter(moment(startDate), 'day') &&
        moment(el.date).isSameOrBefore(moment(endDate), 'day')
    ) {
        return true
    } else {
        return false
    }
}
export function toPercent(value: number) {
    return Math.round(value * 100) + '%'
}
