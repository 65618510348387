<template>
    <span>
        <slot name="activator" :selected="selected" :_id="activatorId">
            <input
                :id="activatorId"
                :value="label"
                readonly
                class="GCB2-audience-setting-base-input select"
            />
        </slot>
        <v-menu
            :close-on-content-click="false"
            :open-on-click="openOnClick"
            v-model="menuOpened"
            :activator="`#${activatorId}`"
        >
            <v-card scrollable :width="width" class="card" :height="cardHeight">
                <v-card-title class="header">
                    <div class="header__content">
                        <v-text-field
                            color="main"
                            :label="label"
                            dense
                            v-model="search"
                            hide-details
                            style="width:100%"
                            append-icon="mdi-magnify"
                            class="header__search-input pt-0"
                            filled
                        >
                        </v-text-field>
                    </div>
                </v-card-title>
                <v-card-text style=" overflow:hidden">
                    <div
                        v-if="menuOpened"
                        ref="treeView"
                        class="vue-virtual-scroll-tree"
                        :style="{ height: `${option.height}px` }"
                        @scroll="handleScroll"
                    >
                        <!-- Base -->
                        <div class="vue-virtual-scroll-tree__base" :style="{ height: baseHeight + 'px' }" />

                        <!-- Empty Block -->
                        <div v-if="listData.length === 0" class="vue-virtual-scroll-tree__empty-block">
                            <span class="empty-text">{{ emptyText }}</span>
                        </div>

                        <!-- Tree List -->
                        <div
                            v-else
                            class="vue-virtual-scroll-tree__list"
                            :style="{ transform: `translateY(${offset}px)` }"
                        >
                            <!-- Tree Item -->
                            <div
                                v-for="(item, index) in listData"
                                :key="item.id"
                                :class="{
                                    ' vue-virtual-scroll-tree__item': true,
                                    disabled: item.disabled,
                                }"
                                :style="{
                                    height: `${option.itemHeight}px`,
                                }"
                            >
                                <!-- Indent -->
                                <span class="vue-virtual-scroll-tree__indent">
                                    <span
                                        v-for="(indent, key) in item.indents"
                                        :key="key"
                                        :class="{ 'indent-unit': true, line: showLine }"
                                    />
                                </span>

                                <!-- Expand -->

                                <!-- Content -->
                                <div class="vue-virtual-scroll-tree__content" @click="handleClick(item)">
                                    <template>
                                        <template>
                                            <v-simple-checkbox
                                                v-if="!item.children"
                                                class="mr-2"
                                                @click="handleCheckboxClick(item)"
                                                :value="Boolean(valueMap[item.id])"
                                                color="main"
                                                :ripple="false"
                                            ></v-simple-checkbox>
                                            <div v-else>
                                                <v-icon
                                                    class="mr-2"
                                                    @click.stop="handleCheckboxClick(item)"
                                                    :color="getTopLevelCheckboxIcon(item).color"
                                                    >{{ getTopLevelCheckboxIcon(item).icon }}</v-icon
                                                >
                                                <v-icon class="mr-2 c">{{
                                                    item.expand ? 'mdi-chevron-up' : 'mdi-chevron-down'
                                                }}</v-icon>
                                            </div>
                                        </template>
                                    </template>

                                    <slot :item="item" :index="index">
                                        <span :title="item.name"> {{ item.name }}</span>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>
    </span>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

let lastTime = 0

export default {
    name: 'VueVirtualScrollTree',
    components: {},
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: 'Поиск...',
        },
        items: {
            type: Array,
            default: () => [],
        },
        showLine: {
            type: Boolean,
            default: true,
        },
        emptyText: {
            type: String,
            default: 'Нет данных',
        },
        filterNodeMethod: {
            type: Function,
            default: (value, item) => item.name.toLowerCase().includes(value.toLowerCase()),
        },
        defaultExpandAll: {
            type: Boolean,
            default: true,
        },
        timeout: {
            type: Number,
            default: 17,
        },
        transition: { type: Boolean, default: true },
        openOnClick: { type: Boolean, default: true },
        width: { type: Number, default: undefined },
        option: {
            type: Object,
            default: () => ({
                height: 500,
                itemHeight: 48,
            }),
        },
    },
    data() {
        return {
            search: '',
            offset: 0,
            menuOpened: false,
            baseHeight: 0,
            listData: [],
        }
    },
    computed: {
        activatorId() {
            return 'tree-select-activator' + '-' + uuidv4().slice(0, 10)
        },
        valueMap() {
            const map = {}
            this.value.forEach(id => {
                map[id] = true
            })
            return map
        },
        cardHeight() {
            return _.clamp(this.baseHeight, 100, this.option.height) + 52
        },
        flattenTree() {
            const flatten = (treeData, level = 1, parent = null) =>
                treeData.reduce((acc, v) => {
                    if (!v.id) {
                        v.id = uuidv4()
                    }

                    v.level = level
                    v.parent = parent

                    v.indents = []
                    for (let i = 0; i < v.level - 1; i++) {
                        v.indents[i] = false
                    }

                    if (v.disabled === undefined) {
                        v.disabled = false
                    }

                    if (v.locked === undefined) {
                        v.locked = false
                    }

                    if (v.expand === undefined) {
                        v.expand = this.defaultExpandAll
                    }

                    if (v.visible === undefined) {
                        v.visible = true
                    }

                    if (!parent.visible || !parent.expand) {
                        v.visible = false
                    }

                    acc.push(v)
                    if (v.children) {
                        acc.push(...flatten(v.children, level + 1, v))
                    }

                    return acc
                }, [])
            const clone = _.cloneDeep(this.items)
            return flatten(clone, 1, {
                id: uuidv4(),
                level: 0,
                visible: true,
                expand: true,
                children: clone,
            })
        },

        visibleListCount() {
            return Math.floor(this.option.height / this.option.itemHeight)
        },
        selected() {
            return this.flattenTree.filter(el => this.valueMap[el.id])
        },
    },
    watch: {
        items(v) {
            this.updateTreeView()
        },
        menuOpened(v) {
            if (v) {
                setTimeout(() => this.updateTreeView(), 10)
                this.search = ''
            }
        },
        search(v) {
            this.filter(v)
        },
        selected(v) {
            this.$emit('update-selected', this.selected)
        },
    },
    methods: {
        handleClick(item) {
            this.toogleCheckbox(item)
            if (item.children) {
                this.toggleExpand(item)
            }

            if (!item.disabled) {
                this.$emit('node-click', item)
            }
        },

        filter(value) {
            console.log('m', this.filterNodeMethod)
            if (!this.filterNodeMethod)
                throw new Error('[VueVirtualScrollTree] filterNodeMethod is required when filter')

            const filterVisible = item => {
                item.visible = true
                item.expand = true
                item.locked = false
                if (item.parent) {
                    filterVisible(item.parent)
                }
            }

            this.flattenTree.forEach(item => {
                if (this.filterNodeMethod(value, item)) {
                    filterVisible(item)
                } else {
                    item.visible = false
                    item.locked = true
                }
            })

            this.updateTreeView()
        },

        updateTreeView() {
            const visibleCount = this.flattenTree.filter(item => item.visible).length
            this.baseHeight = visibleCount * this.option.itemHeight
            this.handleScroll()
        },

        handleScroll() {
            const currentTime = +new Date()
            if (currentTime - lastTime > this.timeout) {
                if (this.$refs.treeView) {
                    this.updateList(this.$refs.treeView.scrollTop)
                    this.updateList(this.$refs.treeView.scrollTop)
                    lastTime = currentTime
                }
            }
        },

        updateList(scrollTop = 0) {
            let start = Math.floor(scrollTop / this.option.itemHeight) - Math.floor(this.visibleListCount / 2)
            start = start < 0 ? 0 : start
            const end = start + this.visibleListCount * 2

            const visibleData = this.flattenTree.filter(v => v.visible)

            if (this.showLine) {
                const siblingMap = new Map()
                const indexMap = new Map()
                visibleData.forEach(v => {
                    const siblingCount = siblingMap.get(v.parent.id)
                    if (siblingCount === undefined) {
                        siblingMap.set(v.parent.id, 1)
                    } else {
                        siblingMap.set(v.parent.id, siblingCount + 1)
                    }

                    indexMap.set(v.parent.id, 0)
                })

                const indentMap = new Map()
                visibleData.forEach(v => {
                    const siblingCount = siblingMap.get(v.parent.id)
                    const selfIndex = indexMap.get(v.parent.id)
                    if (selfIndex === siblingCount - 1) {
                        v.last = true
                        indentMap.set(v.level - 1, false)
                    } else {
                        v.last = false
                        indentMap.set(v.level - 1, true)
                    }

                    indexMap.set(v.parent.id, selfIndex + 1)

                    for (let i = 0; i < v.level - 1; i++) {
                        v.indents[i] = indentMap.get(i)
                    }
                })
            }

            this.listData = visibleData.slice(start, end)

            this.offset = start * this.option.itemHeight
        },

        toggleExpand(item) {
            if (item.expand) {
                this.collapse(item, true)
            } else {
                this.expand(item, true)
            }

            this.updateTreeView()
        },

        expand(item) {
            item.expand = true
            this.updateExpand(item.children, true)
        },

        collapse(item) {
            item.expand = false
            this.updateExpand(item.children, false)
        },

        updateExpand(children, visible) {
            children &&
                children.forEach(node => {
                    if (!node.locked) {
                        node.visible = visible
                        if (node.children && node.expand) {
                            this.updateExpand(node.children, visible)
                        }
                    }
                })
        },

        expandAll() {
            this.flattenTree.forEach(item => {
                if (!item.locked) {
                    item.expand = true
                    item.visible = true
                }
            })

            this.updateTreeView()
        },

        collapseAll(level = 1) {
            this.flattenTree.forEach(item => {
                item.expand = false
                if (item.level !== level) {
                    item.visible = false
                }
            })

            this.updateTreeView()
        },
        handleCheckboxClick(item) {
            if (item.children) {
                //Выбрано все
                if (item.children.every(c => this.valueMap[c.id])) {
                    this.update(
                        _.difference(
                            this.value,
                            item.children.map(el => el.id)
                        )
                    )
                }
                //Не выбрано ничего
                else {
                    this.update(
                        _.uniq(
                            _.concat(
                                this.value,
                                item.children.map(el => el.id)
                            )
                        )
                    )
                }
            } else {
                this.toogleCheckbox(item)
            }
        },
        toogleCheckbox(item) {
            if (item.children) {
            } else {
                if (this.valueMap[item.id]) {
                    this.update(this.value.filter(id => id !== item.id))
                } else {
                    this.update(this.value.concat(item.id))
                }
            }
        },
        getTopLevelCheckboxIcon(item) {
            if (item.children.every(c => this.valueMap[c.id]))
                return { icon: 'mdi-checkbox-outline', color: 'main' }
            if (item.children.every(c => !this.valueMap[c.id]))
                return { icon: 'mdi-checkbox-blank-outline', color: undefined }
            return { icon: 'mdi-minus-box-outline', color: 'main' }
        },
        update(val) {
            this.$emit('input', val)
        },
    },
}
</script>

<style scoped lang="sass">
@import @/vars.sass
.vue-virtual-scroll-tree
    position: relative
    overflow: auto
    &__base
        position: absolute
        left: 0
        top: 0
        right: 0
        z-index: -1
    &__empty-block
        position: relative
        min-height: 100px
        text-align: center
        width: 100%
        .empty-text
            position: absolute
            left: 50%
            top: 50%
            -webkit-transform: translate(-50%, -50%)
            transform: translate(-50%, -50%)
            color: #909399
            font-size: 14px
    &__list
        position: absolute
        left: 0
        right: 0
        top: 0
        min-height: 100px
    &__item
        display: flex
        align-items: flex-start
        outline: none
        &:hover
            background-color: $light-gray-5
        &:focus
            background-color: $light-gray-5
        &.disabled
            color: rgba(0, 0, 0, 0.25)
            cursor: not-allowed
        .vue-virtual-scroll-tree__content
            width: 100%
            display: flex
            align-items: center
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
    &__content
        display: flex
        align-items: center
        width: 100%
        position: relative
        z-index: auto
        height: 100%
        margin: 0
        padding: 0 4px
        line-height: 24px
        background: transparent
        border-radius: 2px
        cursor: pointer
        user-select: none
    &__expand
        padding: 6px
        cursor: pointer
        color: #c0c4cc
        font-size: 12px
        transform: rotate(0deg)
        /* transition: transform .3s ease-in-out; */
        &.expanded
            transform: rotate(90deg)
    &__icon
        margin-right: 8px
    &__indent
        align-self: stretch
        white-space: nowrap
        user-select: none

        .indent-unit
            display: inline-block
            width: 24px
            position: relative
            height: 100%
            &.line
                &::before
                    position: absolute
                    top: 0
                    right: 11px
                    bottom: 0
                    border-right: 1px solid #d9d9d9
                    content: ""







.header
    border-bottom: 1px solid $light-gray-4
    &__content
        display: flex
        align-items: center
        width: 100%
    &__search-input
        background-color: $light-gray-5
        flex-grow: 1
.card
    min-width: 200px
.v-card__title
    padding-top:0px
    padding-bottom:0px
    padding-left:0px
    padding-right:0px
.selection-item-wrapper
    width: 100%
    height:48px
    display: flex
    align-items: center
    overflow: hidden
    text-overflow: ellipsis
.selection-item
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    white-space: nowrap
    align-self
    overflow: hidden
    text-overflow: ellipsis
.selection-item-title.disabled
    opacity: 0.7
::v-deep .v-simple-checkbox--disabled
    opacity: 0.7
.v-card__text
    padding:0
::v-deep .v-text-field__slot input
    color: $text-color
    font-size: 14px
::v-deep label
    font-size: 14px
    color: $gray !important
.highlighted
    background-color: $main-light
.v-list
    padding: 0
.disabled
    color: $gray-2
    pointer-events: none
    cursor: default
::v-deep .v-input__slot:before
    border-color: $light-gray-4 !important
*
    scrollbar-width: auto
    scrollbar-color: $light-gray #ffffff

*::-webkit-scrollbar
    width: 14px
*::-webkit-scrollbar-track
    background: #ffffff

*::-webkit-scrollbar-thumb
    background-color: $gray-2
    border-radius: 8px
    border: 4px solid #ffffff
*::-webkit-scrollbar-thumb:hover
    background-color: $gray
</style>
