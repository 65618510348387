
<template>
    <div>
        <p>
            Например, клиент
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.clientName }}</span> был
            у вас
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.attCount }}</span>
            {{ declOfNum(subHeaderData.attCount, texts) }}, но больше ни разу не пришел к вам после услуги
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.serviceTittle }}</span
            >, которую
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.lastDate)
            }}</span>
            оказал(а)
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.staffName }}</span
            >. В таблице приведены все такие неудачные визиты, которые произошли за период с
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.startDate)
            }}</span>
            по
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.endDate)
            }}</span>
        </p>
    </div>
</template>


<script>
import { declOfNum } from '@/utils'
export default {
    name: 'DidntReturnStaffGistSubHeader',

    props: {
        subHeaderData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        dateFormat: 'DD.MM.YYYY',
        texts: ['раз', 'раза', 'раз'],
    }),
    methods: {
        declOfNum,
        formatDate(date) {
            return this.moment(date).format(this.dateFormat)
        },
    },
}
</script>
<style lang="sass" scoped>
</style>
