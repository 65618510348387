<template>
    <div class="mb-16">
        <DidntReturnStaffGistSubHeader :subHeaderData="subHeaderData"></DidntReturnStaffGistSubHeader>
        <v-data-table
            :sort-by.sync="sortColumn"
            :sort-desc.sync="sortDesc"
            :headers="headers"
            :items="items"
            @current-items="setSubHeaderData"
        >
            <template v-slot:[`item.left_count`]="{ item }">
                <div class="bar-wrapper">
                    <span style="width: 50px">{{ item.left_count }}</span>
                    <div
                        class="bar ml-4"
                        :style="
                            `width:${(item.left_count / maxLeftCount) *
                                100 *
                                (fullBarWidth / 100)}px; background-color:${CHART_COLORS.COLOR1}`
                        "
                    ></div>
                </div>
            </template>
            <template v-slot:[`item.left_proportion`]="{ item }">
                <div class="bar-wrapper">
                    <span style="width: 50px">{{ item.left_proportion + '%' }}</span>
                    <div
                        class="bar ml-4"
                        :style="
                            `width:${item.left_proportion * (fullBarWidth / 100)}px ; background-color:${
                                CHART_COLORS.COLOR2
                            }`
                        "
                    ></div>
                </div> </template
        ></v-data-table>
    </div>
</template>

<script>
import { maxBy } from 'lodash'
import { toPercent } from '../helpers'
import DidntReturnStaffGistSubHeader from './subHeaders/DidntReturnStaffGistSubHeader.vue'
import { CHART_COLORS } from '@/vars/charts.js'
export default {
    name: 'DidntReturnStaffGist',
    components: { DidntReturnStaffGistSubHeader },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        dateBorders: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        CHART_COLORS,
        sortColumn: 'left_count',
        sortDesc: true,
        headers: [
            {
                text: 'Сотрудник',
                align: 'start',
                value: 'staff_name',
            },
            {
                text: 'Количество ушедших',
                align: 'start',
                value: 'left_count',
            },
            {
                text: '',
                value: 'spacer',
            },
            {
                text: 'Доля от общего кол-ва посещений мастера',
                value: 'left_proportion',
            },
        ],
        fullBarWidth: 200,
        subHeaderData: {},
    }),
    computed: {
        maxLeftCount() {
            return maxBy(this.chartData, 'left_count').left_count
        },
        items() {
            return this.chartData.filter(el => el.left_count)
        },
    },
    methods: {
        toPercent,

        setSubHeaderData(curItems) {
            if (curItems.length > 0)
                this.subHeaderData = {
                    startDate: this.dateBorders.from,
                    endDate: this.dateBorders.to,
                    name: curItems[0].staff_name,
                    value: curItems[0].left_count,
                    valueProportion: `${Number(curItems[0].left_proportion)}%`,
                }
        },
    },

    created() {},
}
</script>
<style lang="sass" scoped>
.bar-wrapper
    display: flex
    flex-direction: row
    align-items: center
.bar

    height: 20px
    background-color: #378EFF
</style>
