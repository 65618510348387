
<template>
    <div>
        <p>
            Количество клиентов, которые посетили вас как минимум один раз, потом впервые попали к данному
            сотруднику, а после этого посещения больше ни разу не возвращались к вам. Например, за период с
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.startDate)
            }}</span>
            по
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.endDate)
            }}</span
            >, в
            <span class="gcb-returnability-subheader-highlight-text">{{
                subHeaderData.valueProportion
            }}</span>
            случаев старые клиенты не возвращались к вам после посещения сотрудника
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.name }}</span
            >. В итоге к вам {{ declOfNum(subHeaderData.value, texts1) }}
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.value }}</span>
            {{ declOfNum(subHeaderData.value, texts2) }}
        </p>
    </div>
</template>


<script>
import { declOfNum } from '@/utils'
export default {
    name: 'DidntReturnStaffGistSubHeader',

    props: {
        subHeaderData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        dateFormat: 'DD.MM.YYYY',
        texts1: ['не вернулся', 'не вернулись', 'не вернулось'],
        texts2: ['клиент', 'клиента', 'клиентов'],
    }),
    methods: {
        declOfNum,
        formatDate(date) {
            return this.moment(date).format(this.dateFormat)
        },
    },
}
</script>
<style lang="sass" scoped>
</style>
