<template>
    <v-sheet class="pa-3">
        <v-row class="ma-0" align="center">
            <PhoneLink :hrefInfo="hrefInfo"></PhoneLink>
            <div class="ml-4">
                <AmoCrmLink :hrefInfo="hrefInfo"></AmoCrmLink>
                <YClientsLink :hrefInfo="hrefInfo"></YClientsLink>
                <ProfSalonLink :hrefInfo="hrefInfo"></ProfSalonLink>
                <SonlineLink :hrefInfo="hrefInfo"></SonlineLink>
                <AltegioLink :hrefInfo="hrefInfo" />
                <DikidiLink :hrefInfo="hrefInfo" />
                <ChatLink
                    :hrefInfo="hrefInfo"
                    :chatLinkSettings="chatLinkSettings"
                />
            </div>
        </v-row>
    </v-sheet>
</template>
<script>
import YClientsLink from '@/components/iconLinks/YClientsLink'
import ProfSalonLink from '@/components/iconLinks/ProfSalonLink'
import PhoneLink from '@/components/iconLinks/PhoneLink.vue'
import AltegioLink from '@/components/iconLinks/AltegioLink.vue'
import DikidiLink from '@/components/iconLinks/DikidiLink.vue'
import SonlineLink from './SonlineLink.vue'
import AmoCrmLink from './AmoCrmLink.vue'
import ChatLink from './ChatLink.vue'

export default {
    components: {
        YClientsLink,
        ChatLink,
        ProfSalonLink,
        SonlineLink,
        PhoneLink,
        AltegioLink,
        DikidiLink,
        AmoCrmLink,
    },
    computed: {
        chatLinkSettings() {
            return this.connector?.whatsappLinkSettings
        },
    },
    props: {
        hrefInfo: {
            type: Array,
            default: () => [],
        },
        connector: {
            type: Object,
            default: () => {},
        },
    },
}
</script>